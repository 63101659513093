.markdown-preview {
    display: flex;
    flex-grow: 1;
}

.markdown-editor {
    flex-basis: 50%;
    padding: 2rem;
    background-color: #f0f0f0;
}

.markdown-editor textarea {
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding: 1rem;
    border: none;
    outline: none;
    resize: none;
    background-color: #f0f0f0;
}

.markdown-preview-area {
    flex-basis: 50%;
    padding: 2rem;
    background-color: #877c7c;
    overflow-y: auto;
}